import React from "react"
import Layout from "../../components/layout"

export default function Thanks() {
  return (
    <Layout>
      <section className="bordered m-y-3 text-center">
        <h1 className="m-y-2">Thanks!!!</h1>
      </section>
    </Layout>

  )
}